$body-bg: #f2f4f4;
$bodyTextColor: #727e8c;
$primary: #f58025;
$secondary: #259af5;

/* Text */
$primaryText: #ffffff;
$secondaryText: #000000;
$tertiaryText: #475f7b;
$invalidText: #dc3545;

/* Label */
$labelFontSize: 0.8em;

/* Border */
$borderColor: #dfe3e7;

$primaryDark: #098dbb;
$seondaryDark: #1c538d;
// $accent: #1b73aa;
// $titleColor: #25282b;
$inActive: #a0a4a8;
// $placeholderColor: #cacccf;
// $tertiary: #52575c;

// New colors for campaign status
// $draft: #eec23c;
// $running: #27ae60;
// $not_sufficient: #ff8d00;
// $stopped: #d80000;
// $finished: #808080;

// New colors for buy credit
// $promo: #eb5757;
// $orderSummary_title: #f1f1ef;
// $pay88: #fe6600;
// $failed: #ee0004;

// New colors for SMS Analytics
// $sliderItem: #05ff00;

$heaidngfontSize: 1.4em;
// $subHeadingFontSize: 1.2em;
// $paraFontSize: 0.9em;
// $paraSmFontSize: 0.8em;
// $linkFontSize: 0.8em;
$inputFontSize: 0.9em;
$btnFontSize: 1em;

// $smFontSize: 0.8em;
$mdFontSize: 0.9em;
// $lgFontSize: 1em;
// $pageTitleFontSize: 1.3em;

// $regularFontWeight: 400;
$boldFontWeight: 700;
// $bolderFontWeight: 900;

$btnBorderRadius: 4px;
$inputBorderRadius: 4px;
// $cardBorderRadius: 4px;
// $borderRadius: 4px;
